import React, { useState, useEffect, useMemo } from 'react';
import api from '../api';
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  Link,
  Tooltip,
  Snackbar,
  Switch,
  FormControlLabel,
  Pagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Edit, Delete, Link as LinkIcon, ContentCopy, FileDownload, Share } from '@mui/icons-material';

const superpowerSkillsOptions = [
  'PROBLEM_SOLVING',
  'TEAMWORK',
  'COMMUNICATION',
  'LEADERSHIP',
  'CREATIVITY',
];

const jobSkillsOptions = [
  'NODE_JS',
  'EXPRESS',
  'PRISMA',
  'REACT',
  'TYPESCRIPT',
];

// Replace makeStyles with styled components
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

// Helper function to safely format the utm_term
const formatUtmTerm = (job) => {
  const companyName = job.client?.companyName || 'unknown_company';
  const jobTitle = job.title || job.role || 'unknown_job';
  const jobId = job.id || 'unknown_id';

  return `${companyName.toLowerCase().replace(/\s+/g, '_')}_${jobTitle.toLowerCase().replace(/\s+/g, '_')}_${jobId}`;
};

function Jobs() {
  const [jobs, setJobs] = useState([]);
  const [clients, setClients] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [mergeIdFilter, setMergeIdFilter] = useState('all'); // 'all', 'with', 'without'

  const emptyAttribute = { attributeKey: '', description: '', scoringGuide: {} };
  const emptyQuestion = '';

  const [currentJob, setCurrentJob] = useState({
    id: null,
    associatedClientEmail: '',
    hiringManagerEmail: '',
    title: '',
    role: '',
    questions: [emptyQuestion],
    maxTimePerQuestion: 0,
    maxInterviewTime: 0,
    aiModel: 'async_performance_grader',
    organizationSize: '',
    superpowerSkills: [],
    jobSkills: [],
    experienceLevel: '',
    description: '',
    attributes: [emptyAttribute], // Initialize with one empty attribute
    sendEmailOnCompletion: true,
    interviewUrlOverride: '',
  });

  const [defaultAttributes, setDefaultAttributes] = useState([]);

  const [sortField, setSortField] = useState('title');
  const [sortOrder, setSortOrder] = useState('asc');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [selectedClient, setSelectedClient] = useState('');

  useEffect(() => {
    fetchJobs();
    fetchClients();
    fetchDefaultAttributes();
  }, [page, itemsPerPage, sortField, sortOrder, selectedClient, mergeIdFilter]);

  const fetchJobs = async () => {
    try {
      const response = await api.get('/jobs', {
        params: {
          page,
          itemsPerPage,
          sortField,
          sortOrder,
          clientId: selectedClient || undefined,
          mergeIdFilter,
        }
      });
      setJobs(response.data.jobs);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  const fetchClients = async () => {
    try {
      // Request all clients by using a large itemsPerPage value
      const response = await api.get('/clients', {
        params: {
          page: 1,
          itemsPerPage: 1000 // Set a high number to get all clients
        }
      });
      setClients(response.data.clients);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const fetchDefaultAttributes = async () => {
    const response = await api.get('/default-attributes');
    setDefaultAttributes(response.data);
  };

  const handleOpen = () => {
    setIsEditing(false);
    setCurrentJob({
      id: null,
      associatedClientEmail: '',
      hiringManagerEmail: '',
      title: '',
      role: '',
      questions: [emptyQuestion],
      maxTimePerQuestion: 0,
      maxInterviewTime: 0,
      aiModel: 'async_performance_grader',
      organizationSize: '',
      superpowerSkills: [],
      jobSkills: [],
      experienceLevel: '',
      description: '',
      attributes: defaultAttributes.map(attr => ({
        attributeKey: attr.attributeKey,
        description: attr.description,
        scoringGuide: attr.scoringGuide,
      })),
      sendEmailOnCompletion: true,
      interviewUrlOverride: '',
    });
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    setCurrentJob({ ...currentJob, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setCurrentJob({ ...currentJob, [name]: value });
  };

  const handleAttributeChange = (index, field, value) => {
    const updatedAttributes = [...currentJob.attributes];
    updatedAttributes[index][field] = value;
    setCurrentJob({ ...currentJob, attributes: updatedAttributes });
  };

  const addAttribute = () => {
    if (currentJob.attributes.length < 5) {
      setCurrentJob({
        ...currentJob,
        attributes: [...currentJob.attributes, { ...emptyAttribute }],
      });
    }
  };

  const removeAttribute = (index) => {
    const updatedAttributes = currentJob.attributes.filter((_, idx) => idx !== index);
    setCurrentJob({ ...currentJob, attributes: updatedAttributes });
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...currentJob.questions];
    updatedQuestions[index] = value;
    setCurrentJob({ ...currentJob, questions: updatedQuestions });
  };

  const addQuestion = () => {
    if (currentJob.questions.length < 5) {
      setCurrentJob({
        ...currentJob,
        questions: [...currentJob.questions, emptyQuestion],
      });
    }
  };

  const removeQuestion = (index) => {
    const updatedQuestions = currentJob.questions.filter((_, idx) => idx !== index);
    setCurrentJob({ ...currentJob, questions: updatedQuestions });
  };

  const handleSubmit = async () => {
    try {
      const jobData = {
        ...currentJob,
        maxTimePerQuestion: parseInt(currentJob.maxTimePerQuestion),
        maxInterviewTime: parseInt(currentJob.maxInterviewTime),
      };

      if (isEditing) {
        await api.put(`/jobs/${jobData.id}`, jobData);
      } else {
        await api.post('/jobs', jobData);
      }
      fetchJobs();
      handleClose();
    } catch (error) {
      const errorDetails = error.response?.data?.details;
      if (errorDetails && Array.isArray(errorDetails)) {
        alert('Error saving job:\n\n' + errorDetails.join('\n'));
      } else {
        alert('Error saving job');
      }
    }
  };

  const handleEdit = async (job) => {
    try {
      setIsEditing(true);
      setCurrentJob({
        ...job,
        questions: job.questions.length > 0 ? job.questions : [emptyQuestion],
        attributes: job.attributes.length > 0 ? job.attributes : [emptyAttribute],
      });
      setOpen(true);
    } catch (error) {
      console.error("Error in handleEdit:", error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this job?')) {
      try {
        await api.delete(`/jobs/${id}`);
        fetchJobs();
      } catch (error) {
        alert('Error deleting job');
      }
    }
  };

  const handleCopyUrl = (job) => {
    const baseUrl = job.interviewURL;
    const utmParams = new URLSearchParams({
      utm_source: 'admin_interface',
      utm_medium: 'dashboard',
      utm_campaign: 'job_interviews',
      utm_content: 'copy_link',
      utm_term: formatUtmTerm(job)
    });

    const separator = baseUrl.includes('?') ? '&' : '?';
    const urlWithUtm = `${baseUrl}${separator}${utmParams.toString()}`;

    navigator.clipboard.writeText(urlWithUtm).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleToggleEmailNotification = async (jobId, currentValue) => {
    try {
      const job = jobs.find((job) => job.id === jobId);
      if (!job) throw new Error("Job not found");

      await api.put(`/jobs/${jobId}`, {
        ...job,
        sendEmailOnCompletion: !currentValue,
      });
      fetchJobs(); // Refresh the jobs list to reflect the change
    } catch (error) {
      alert('Error updating email notification setting');
    }
  };

  const handleSort = (field) => {
    setSortOrder(currentOrder => {
      if (sortField === field) {
        return currentOrder === 'asc' ? 'desc' : 'asc';
      }
      return 'asc';
    });
    setSortField(field);
  };

  const handleAddDefaultAttribute = () => {
    if (defaultAttributes.length === 0) {
      alert('No default attributes available');
      return;
    }

    const dialog = window.confirm('Adding default attributes will replace all current attributes. Continue?');
    if (dialog) {
      setCurrentJob({
        ...currentJob,
        attributes: defaultAttributes.map(attr => ({
          attributeKey: attr.attributeKey,
          description: attr.description,
          scoringGuide: attr.scoringGuide,
        }))
      });
    }
  };

  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
    setPage(1); // Reset to first page when changing client filter
  };

  const handleExport = async () => {
    try {
      const response = await api.get('/export/jobs', {
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `jobs-${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting jobs:', error);
      alert('Error exporting jobs');
    }
  };

  const handleGenerateEditLink = async (jobId) => {
    try {
      const response = await api.post(`/jobs/${jobId}/generate-edit-link`);
      const { url } = response.data;

      await navigator.clipboard.writeText(url);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error generating edit link:', error);
      alert('Error generating edit link');
    }
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Jobs
      </Typography>
      <div style={{ display: 'flex', gap: '20px', marginBottom: '20px', alignItems: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Add New Job
        </Button>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<FileDownload />}
          onClick={handleExport}
        >
          Export to CSV
        </Button>
        <FormControl sx={{ minWidth: 200 }}>
          <Select
            value={selectedClient}
            onChange={handleClientChange}
            displayEmpty
            renderValue={(selected) => {
              if (selected === '') {
                return 'Filter by Client';
              }
              const client = clients.find(
                (client) => client.id === parseInt(selected)
              );
              return client ? client.companyName : '';
            }}
          >
            <MenuItem value="">All Clients</MenuItem>
            {clients?.map((client) => (
              <MenuItem key={client.id} value={client.id}>{client.companyName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ minWidth: 200 }}>
          <Select
            value={mergeIdFilter}
            onChange={(e) => setMergeIdFilter(e.target.value)}
            displayEmpty
            renderValue={(selected) => {
              if (selected === 'all') return "All Jobs";
              if (selected === 'with') return "With Merge ID";
              return "Without Merge ID";
            }}
          >
            <MenuItem value="all">All Jobs</MenuItem>
            <MenuItem value="with">With Merge ID</MenuItem>
            <MenuItem value="without">Without Merge ID</MenuItem>
          </Select>
        </FormControl>
      </div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell
                onClick={() => handleSort('mergeID')}
                style={{ cursor: 'pointer' }}
              >
                Merge ID {sortField === 'mergeID' && (sortOrder === 'asc' ? '↑' : '↓')}
              </TableCell>
              <TableCell
                onClick={() => handleSort('title')}
                style={{ cursor: 'pointer' }}
              >
                Title {sortField === 'title' && (sortOrder === 'asc' ? '↑' : '↓')}
              </TableCell>
              <TableCell
                onClick={() => handleSort('client.companyName')}
                style={{ cursor: 'pointer' }}
              >
                Client {sortField === 'client.companyName' && (sortOrder === 'asc' ? '↑' : '↓')}
              </TableCell>
              <TableCell
                onClick={() => handleSort('hiringManagerEmail')}
                style={{ cursor: 'pointer' }}
              >
                Hiring Manager Email {sortField === 'hiringManagerEmail' && (sortOrder === 'asc' ? '↑' : '↓')}
              </TableCell>
              <TableCell
                onClick={() => handleSort('role')}
                style={{ cursor: 'pointer' }}
              >
                Role {sortField === 'role' && (sortOrder === 'asc' ? '↑' : '↓')}
              </TableCell>
              <TableCell>Interview URL</TableCell>
              <TableCell>Send Email on Completion</TableCell>
              <TableCell>Interviews</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.map((job) => (
              <TableRow key={job.id}>
                <TableCell>{job.id}</TableCell>
                <TableCell>{job.mergeID || 'N/A'}</TableCell>
                <TableCell>{job.title}</TableCell>
                <TableCell>{job.client?.companyName}</TableCell>
                <TableCell>{job.hiringManagerEmail}</TableCell>
                <TableCell>{job.role}</TableCell>
                <TableCell>
                  {job.interviewURL && (
                    <>
                      <Link
                        href={`${job.interviewURL}${job.interviewURL.includes('?') ? '&' : '?'}utm_source=admin_interface&utm_medium=dashboard&utm_campaign=job_interviews&utm_content=direct_link&utm_term=${encodeURIComponent(formatUtmTerm(job))}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        display="flex"
                        alignItems="center"
                        sx={{ mr: 1 }}
                      >
                        <LinkIcon sx={{ mr: 1 }} />
                        Interview Link
                      </Link>
                      <Tooltip title="Copy URL with UTM parameters">
                        <IconButton
                          size="small"
                          onClick={() => handleCopyUrl(job)}
                        >
                          <ContentCopy fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </TableCell>
                <TableCell>
                  <Switch
                    checked={job.sendEmailOnCompletion}
                    onChange={() => handleToggleEmailNotification(job.id, job.sendEmailOnCompletion)}
                    color="primary"
                  />
                </TableCell>
                <TableCell>{job._count?.interviews || 0}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(job)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="primary" onClick={() => handleGenerateEditLink(job.id)}>
                    <Share />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDelete(job.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px'
      }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
        />
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{isEditing ? 'Edit Job' : 'Add New Job'}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel>Associated Client</InputLabel>
            <Select
              name="associatedClientEmail"
              value={currentJob.associatedClientEmail}
              label="Associated Client"
              onChange={handleSelectChange}
            >
              {clients?.map((client) => (
                <MenuItem key={client.contactEmail} value={client.contactEmail}>
                  {client.companyName} ({client.contactEmail})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            name="hiringManagerEmail"
            label="Hiring Manager Email"
            type="email"
            fullWidth
            variant="standard"
            value={currentJob.hiringManagerEmail}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="title"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            value={currentJob.title}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="role"
            label="Role (Optional)"
            type="text"
            fullWidth
            variant="standard"
            value={currentJob.role}
            onChange={handleInputChange}
          />
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Questions
          </Typography>
          {currentJob.questions.map((question, index) => (
            <Paper key={index} sx={{ p: 2, mt: 2 }}>
              <TextField
                margin="dense"
                label={`Question ${index + 1}`}
                fullWidth
                value={question}
                onChange={(e) => handleQuestionChange(index, e.target.value)}
              />
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => removeQuestion(index)}
                sx={{ mt: 1 }}
                disabled={currentJob.questions.length === 1}
              >
                Remove Question
              </Button>
            </Paper>
          ))}
          {currentJob.questions.length < 5 && (
            <Button
              variant="contained"
              color="primary"
              onClick={addQuestion}
              sx={{ mt: 2 }}
            >
              Add Question
            </Button>
          )}
          <TextField
            margin="dense"
            name="maxTimePerQuestion"
            label="Max Time Per Question (in seconds)"
            helperText="Please enter time in seconds"
            type="number"
            fullWidth
            variant="standard"
            value={currentJob.maxTimePerQuestion}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="maxInterviewTime"
            label="Max Interview Time (in seconds)"
            helperText="Please enter time in seconds"
            type="number"
            fullWidth
            variant="standard"
            value={currentJob.maxInterviewTime}
            onChange={handleInputChange}
          />
          <input
            type="hidden"
            name="aiModel"
            value={currentJob.aiModel}
          />
          <input
            type="hidden"
            name="organizationSize"
            value={currentJob.organizationSize}
          />
          <StyledFormControl fullWidth variant="outlined">
            <InputLabel id="superpower-skills-label">Superpower Skills (Optional)</InputLabel>
            <Select
              labelId="superpower-skills-label"
              id="superpower-skills-select"
              multiple
              name="superpowerSkills"
              value={currentJob.superpowerSkills}
              onChange={handleSelectChange}
              renderValue={(selected) => selected.join(', ')}
              label="Superpower Skills (Optional)"
            >
              {superpowerSkillsOptions.map((skill) => (
                <MenuItem key={skill} value={skill}>
                  <Checkbox checked={currentJob.superpowerSkills.includes(skill)} />
                  <ListItemText primary={skill} />
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <StyledFormControl fullWidth variant="outlined">
            <InputLabel id="job-skills-label">Job Skills (Optional)</InputLabel>
            <Select
              labelId="job-skills-label"
              id="job-skills-select"
              multiple
              name="jobSkills"
              value={currentJob.jobSkills}
              onChange={handleSelectChange}
              renderValue={(selected) => selected.join(', ')}
              label="Job Skills (Optional)"
            >
              {jobSkillsOptions.map((skill) => (
                <MenuItem key={skill} value={skill}>
                  <Checkbox checked={currentJob.jobSkills.includes(skill)} />
                  <ListItemText primary={skill} />
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <TextField
            margin="dense"
            name="experienceLevel"
            label="Experience Level (Optional)"
            type="text"
            fullWidth
            variant="standard"
            value={currentJob.experienceLevel}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            multiline
            minRows={6}
            maxRows={12}
            variant="outlined"
            value={currentJob.description}
            onChange={handleInputChange}
            sx={{
              mt: 3,
              mb: 2,
              '& .MuiOutlinedInput-root': {
                '& textarea': {
                  lineHeight: 1.5,
                }
              }
            }}
            InputProps={{
              style: {
                fontSize: '1rem',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
              }
            }}
          />
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Attributes
            <Button
              variant="outlined"
              color="primary"
              onClick={handleAddDefaultAttribute}
              sx={{ ml: 2 }}
              disabled={defaultAttributes.length === 0}
            >
              Use Default Attributes
            </Button>
          </Typography>
          {currentJob.attributes.map((attribute, index) => (
            <Paper key={index} sx={{ p: 2, mt: 2 }}>
              <TextField
                margin="dense"
                label="Attribute Key"
                fullWidth
                value={attribute.attributeKey}
                onChange={(e) =>
                  handleAttributeChange(index, 'attributeKey', e.target.value)
                }
              />
              <TextField
                margin="dense"
                label="Description"
                fullWidth
                multiline
                rows={2}
                value={attribute.description}
                onChange={(e) =>
                  handleAttributeChange(index, 'description', e.target.value)
                }
              />
              <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                Scoring Guide
              </Typography>
              {Array.from({ length: 5 }, (_, i) => i + 1).map((score) => (
                <TextField
                  key={score}
                  margin="dense"
                  label={`Score ${score}`}
                  fullWidth
                  multiline
                  rows={2}
                  value={attribute.scoringGuide[score] || ''}
                  onChange={(e) =>
                    handleAttributeChange(index, 'scoringGuide', {
                      ...attribute.scoringGuide,
                      [score]: e.target.value,
                    })
                  }
                />
              ))}
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => removeAttribute(index)}
                sx={{ mt: 1 }}
                disabled={currentJob.attributes.length === 1}
              >
                Remove Attribute
              </Button>
            </Paper>
          ))}
          {currentJob.attributes.length < 5 && (
            <Button
              variant="contained"
              color="primary"
              onClick={addAttribute}
              sx={{ mt: 2 }}
            >
              Add Attribute
            </Button>
          )}
          <TextField
            margin="dense"
            name="interviewUrlOverride"
            label="Interview URL Override"
            type="text"
            fullWidth
            variant="standard"
            value={currentJob.interviewUrlOverride}
            onChange={handleInputChange}
            placeholder={process.env.REACT_APP_INTERVIEW_BASE_URL}
            helperText="Leave empty to use default interview URL"
          />
          <FormControlLabel
            control={
              <Switch
                checked={currentJob.sendEmailOnCompletion}
                onChange={(e) =>
                  setCurrentJob({ ...currentJob, sendEmailOnCompletion: e.target.checked })
                }
                name="sendEmailOnCompletion"
                color="primary"
              />
            }
            label="Send Email on Interview Completion"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>
            {isEditing ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="URL copied to clipboard"
      />
    </>
  );
}

export default Jobs;
