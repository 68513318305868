import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import api from '../api';
import {
    Typography,
    TextField,
    Paper,
    Button,
    Container,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Checkbox,
    ListItemText,
    Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
}));

const StyledContainer = styled(Container)(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
}));

const superpowerSkillsOptions = [
    'PROBLEM_SOLVING',
    'TEAMWORK',
    'COMMUNICATION',
    'LEADERSHIP',
    'CREATIVITY',
];

const jobSkillsOptions = [
    'NODE_JS',
    'EXPRESS',
    'PRISMA',
    'REACT',
    'TYPESCRIPT',
];

function JobEditor() {
    const { mergeId } = useParams();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');

    const emptyAttribute = { attributeKey: '', description: '', scoringGuide: {} };
    const emptyQuestion = '';

    useEffect(() => {
        const fetchJob = async () => {
            try {
                const response = await api.get(`/jobs/edit/${mergeId}`, {
                    params: { token }
                });
                setJob(response.data);
            } catch (error) {
                setError(error.response?.data?.error || 'Error loading job');
            } finally {
                setLoading(false);
            }
        };

        fetchJob();
    }, [mergeId, token]);

    const handleInputChange = (e) => {
        setJob({ ...job, [e.target.name]: e.target.value });
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setJob({ ...job, [name]: value });
    };

    const handleAttributeChange = (index, field, value) => {
        const updatedAttributes = [...job.attributes];
        updatedAttributes[index][field] = value;
        setJob({ ...job, attributes: updatedAttributes });
    };

    const handleQuestionChange = (index, value) => {
        const updatedQuestions = [...job.questions];
        updatedQuestions[index] = value;
        setJob({ ...job, questions: updatedQuestions });
    };

    const addQuestion = () => {
        if (job.questions.length < 5) {
            setJob({
                ...job,
                questions: [...job.questions, emptyQuestion],
            });
        }
    };

    const removeQuestion = (index) => {
        const updatedQuestions = job.questions.filter((_, idx) => idx !== index);
        setJob({ ...job, questions: updatedQuestions });
    };

    const addAttribute = () => {
        if (job.attributes.length < 5) {
            setJob({
                ...job,
                attributes: [...job.attributes, { ...emptyAttribute }],
            });
        }
    };

    const removeAttribute = (index) => {
        const updatedAttributes = job.attributes.filter((_, idx) => idx !== index);
        setJob({ ...job, attributes: updatedAttributes });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccessMessage('');

        try {
            const jobData = {
                ...job,
                maxTimePerQuestion: parseInt(job.maxTimePerQuestion),
                maxInterviewTime: parseInt(job.maxInterviewTime),
            };

            await api.put(`/jobs/${job.id}`, jobData, {
                params: { token }
            });

            setSuccessMessage('Job updated successfully!');
            window.scrollTo(0, 0);
        } catch (error) {
            const errorDetails = error.response?.data?.details;
            if (errorDetails && Array.isArray(errorDetails)) {
                setError(errorDetails.join('\n'));
            } else {
                setError('Error updating job');
            }
            window.scrollTo(0, 0);
        }
    };

    if (loading) return <StyledContainer><Typography>Loading...</Typography></StyledContainer>;
    if (error) return <StyledContainer><Typography color="error">Error: {error}</Typography></StyledContainer>;
    if (!job) return <StyledContainer><Typography>Job not found</Typography></StyledContainer>;

    return (
        <StyledContainer maxWidth="md">
            <Paper sx={{ p: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Edit Job: {job.title}
                </Typography>

                {successMessage && (
                    <Typography color="success.main" sx={{ mb: 2 }}>
                        {successMessage}
                    </Typography>
                )}

                <form onSubmit={handleSubmit}>
                    <TextField
                        margin="dense"
                        name="title"
                        label="Title"
                        type="text"
                        fullWidth
                        variant="outlined"
                        disabled
                        value={job.title}
                        onChange={handleInputChange}
                        sx={{ mb: 2 }}
                    />

                    <TextField
                        margin="dense"
                        name="role"
                        label="Role (Optional)"
                        type="text"
                        fullWidth
                        variant="outlined"
                        disabled
                        value={job.role}
                        onChange={handleInputChange}
                        sx={{ mb: 2 }}
                    />

                    <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                        Questions
                    </Typography>
                    {job.questions.map((question, index) => (
                        <Paper key={index} sx={{ p: 2, mt: 2 }}>
                            <TextField
                                margin="dense"
                                label={`Question ${index + 1}`}
                                fullWidth
                                disabled
                                value={question}
                                onChange={(e) => handleQuestionChange(index, e.target.value)}
                            />
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => removeQuestion(index)}
                                sx={{ mt: 1 }}
                                disabled={true || job.questions.length === 1}
                            >
                                Remove Question
                            </Button>
                        </Paper>
                    ))}
                    {job.questions.length < 5 && (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled
                            onClick={addQuestion}
                            sx={{ mt: 2 }}
                        >
                            Add Question
                        </Button>
                    )}

                    <Box sx={{ mt: 3 }}>
                        <TextField
                            margin="dense"
                            name="maxTimePerQuestion"
                            label="Max Time Per Question (in seconds)"
                            type="number"
                            fullWidth
                            variant="outlined"
                            disabled
                            value={job.maxTimePerQuestion}
                            onChange={handleInputChange}
                            helperText="Please enter time in seconds"
                        />

                        <TextField
                            margin="dense"
                            name="maxInterviewTime"
                            label="Max Interview Time (in seconds)"
                            type="number"
                            fullWidth
                            variant="outlined"
                            disabled
                            value={job.maxInterviewTime}
                            onChange={handleInputChange}
                            helperText="Please enter time in seconds"
                        />
                    </Box>

                    <StyledFormControl fullWidth>
                        <InputLabel id="superpower-skills-label">Superpower Skills (Optional)</InputLabel>
                        <Select
                            labelId="superpower-skills-label"
                            multiple
                            name="superpowerSkills"
                            disabled
                            value={job.superpowerSkills}
                            onChange={handleSelectChange}
                            renderValue={(selected) => selected.join(', ')}
                            label="Superpower Skills (Optional)"
                        >
                            {superpowerSkillsOptions.map((skill) => (
                                <MenuItem key={skill} value={skill}>
                                    <Checkbox checked={job.superpowerSkills.includes(skill)} />
                                    <ListItemText primary={skill} />
                                </MenuItem>
                            ))}
                        </Select>
                    </StyledFormControl>

                    <StyledFormControl fullWidth>
                        <InputLabel id="job-skills-label">Job Skills (Optional)</InputLabel>
                        <Select
                            labelId="job-skills-label"
                            multiple
                            name="jobSkills"
                            disabled
                            value={job.jobSkills}
                            onChange={handleSelectChange}
                            renderValue={(selected) => selected.join(', ')}
                            label="Job Skills (Optional)"
                        >
                            {jobSkillsOptions.map((skill) => (
                                <MenuItem key={skill} value={skill}>
                                    <Checkbox checked={job.jobSkills.includes(skill)} />
                                    <ListItemText primary={skill} />
                                </MenuItem>
                            ))}
                        </Select>
                    </StyledFormControl>

                    <TextField
                        margin="dense"
                        name="description"
                        label="Description"
                        type="text"
                        fullWidth
                        multiline
                        minRows={6}
                        maxRows={12}
                        variant="outlined"
                        disabled
                        value={job.description}
                        onChange={handleInputChange}
                        sx={{ mt: 3, mb: 2 }}
                    />

                    <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                        Attributes
                    </Typography>
                    {job.attributes.map((attribute, index) => (
                        <Paper key={index} sx={{ p: 2, mt: 2 }}>
                            <TextField
                                margin="dense"
                                label="Attribute Key"
                                fullWidth
                                value={attribute.attributeKey}
                                onChange={(e) =>
                                    handleAttributeChange(index, 'attributeKey', e.target.value)
                                }
                            />
                            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                                Scoring Guide
                            </Typography>
                            {Array.from({ length: 5 }, (_, i) => i + 1).map((score) => (
                                <TextField
                                    key={score}
                                    margin="dense"
                                    label={`Score ${score}`}
                                    fullWidth
                                    multiline
                                    rows={2}
                                    value={attribute.scoringGuide[score] || ''}
                                    onChange={(e) =>
                                        handleAttributeChange(index, 'scoringGuide', {
                                            ...attribute.scoringGuide,
                                            [score]: e.target.value,
                                        })
                                    }
                                />
                            ))}
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => removeAttribute(index)}
                                sx={{ mt: 1 }}
                                disabled={job.attributes.length === 1}
                            >
                                Remove Attribute
                            </Button>
                        </Paper>
                    ))}
                    {job.attributes.length < 5 && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={addAttribute}
                            sx={{ mt: 2 }}
                        >
                            Add Attribute
                        </Button>
                    )}

                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            Update Job
                        </Button>
                    </Box>
                </form>
            </Paper>
        </StyledContainer>
    );
}

export default JobEditor; 