import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Applicants from "./components/Applicants";
import Clients from "./components/Clients";
import Dashboard from "./components/Dashboard";
import Interviews from "./components/Interviews";
import Jobs from "./components/Jobs";
import Layout from "./components/Layout";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import Users from "./components/Users";
import VideoPlayer from "./components/VideoPlayer";
import { AuthProvider } from "./contexts/AuthContext";
import DefaultAttributes from "./components/DefaultAttributes";
import JobEditor from './components/JobEditor';

const theme = createTheme({
  typography: {
    fontFamily: "Graphik, sans-serif",
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#ffffff',  // Pure white for primary actions
    },
    secondary: {
      main: '#666666',  // Mid-gray for secondary elements
    },
    background: {
      default: '#111111', // Nearly black
      paper: '#1c1c1c',   // Slightly lighter black for cards/surfaces
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
    divider: 'rgba(255, 255, 255, 0.12)',
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#1c1c1c',
          borderRight: '1px solid rgba(255, 255, 255, 0.12)',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#111111',
          borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0, // Sharp corners for a stark look
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0, // Sharp corners for a stark look
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/job-editor/:mergeId" element={<JobEditor />} />
            <Route path="/video/:callId" element={<VideoPlayer />} />
            {/* Protected Routes */}
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <Layout>
                    <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/clients" element={<Clients />} />
                      <Route path="/jobs" element={<Jobs />} />
                      <Route path="/applicants" element={<Applicants />} />
                      <Route path="/interviews" element={<Interviews />} />
                      <Route path="/users" element={<Users />} />
                      <Route path="/default-attributes" element={<DefaultAttributes />} />
                    </Routes>
                  </Layout>
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
